<template>
  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <v-card class="elevation-5">
            <div class="primary">
              <v-card-title class="white--text">TIMII</v-card-title>
              <v-card-subtitle class="white--text">
                TCM & Integrative Medicine Institutions Index
                <span class="float-right font-weight-light caption ml-2">v{{ getAppVersion }}</span>
              </v-card-subtitle>
            </div>
            <v-card-text>
             <!--  { invalid, validated, passes, validate } -->
              <validation-observer ref="obs" v-slot="{ invalid, validate }">
                <v-form v-on:submit.prevent="onSubmit">
                  <validation-provider name="Name" rules="required|min:3" v-slot="{ errors }">
                    <v-text-field
                      label="Email"
                      v-model="user.name"
                      :error-messages="errors"                      
                      prepend-icon="mdi-account-tie-outline"
                      type="text"
                      autofocus
                      maxlength="50"
                      autocomplete="username"
                    />
                  </validation-provider>

                  <validation-provider name="Name" rules="required|min:3" v-slot="{ errors }">
                    <v-text-field
                      id="password"
                      label="Contraseña"
                      v-model="user.password"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-outline"
                      type="password"
                      maxlength="20"
                      autocomplete="current-password"
                    />
                  </validation-provider>

                  <v-alert                   
                    :type=" message.status >= '400' ? 'error' : 'success' "
                    v-if="message.statusText.length"
                    dense
                    text
                    class="body-2"
                  >
                    {{ message.status + ": " + message.statusText }}
                  </v-alert>

                  <!-- <v-alert                   
                    :type=" globalMessage.status == '400' ? 'error' : 'success' "
                    v-if="globalMessage.length"
                    dense
                    text
                    class="body-2"
                  >
                    {{ globalMessage[0].status + ": " + globalMessage[0].statusText }}
                  </v-alert> -->
                  
                  <div class="text-right">
                    <v-btn                      
                      type="submit"                      
                      color="teal white--text"                     
                      :disabled="invalid || !validate || loading" 
                    >Login</v-btn>
                  </div>
                </v-form>   
              </validation-observer>   

              <v-overlay
                absolute
                :opacity="0.58"
                :color=" 'white' "
                :value="loading"
              >
                <div class="text-center">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-overlay>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { mapGetters } from "vuex";
  
  export default {
    name: "login",
    components: {
      ValidationProvider,
      ValidationObserver
    },
    data: () => ({
      user: {
        name: "",
        password: "",
      },
      loading: false,
      message: {
        status: 0,
        statusText: ''
      }
    }),
    computed: {
      ...mapGetters(["getAppVersion"])
    },
    methods: {
      /* ...mapActions("auth", ["LOGIN"]), */
      onSubmit() {
        this.message.status = this.message.statusText = ''
        this.loading = true

        this.$store.dispatch("auth/LOGIN", this.user)
        .then( () => { 
          this.loading = false
        })
        .catch( error => {
          this.message = error
          this.loading = false
        })
        //this.$router.push({ name: "dashboard" })
      }
    },
  };
</script>

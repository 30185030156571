var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-card',{staticClass:"elevation-5"},[_c('div',{staticClass:"primary"},[_c('v-card-title',{staticClass:"white--text"},[_vm._v("TIMII")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" TCM & Integrative Medicine Institutions Index "),_c('span',{staticClass:"float-right font-weight-light caption ml-2"},[_vm._v("v"+_vm._s(_vm.getAppVersion))])])],1),_c('v-card-text',[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"prepend-icon":"mdi-account-tie-outline","type":"text","autofocus":"","maxlength":"50","autocomplete":"username"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Contraseña","error-messages":errors,"prepend-icon":"mdi-lock-outline","type":"password","maxlength":"20","autocomplete":"current-password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),(_vm.message.statusText.length)?_c('v-alert',{staticClass:"body-2",attrs:{"type":_vm.message.status >= '400' ? 'error' : 'success',"dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.message.status + ": " + _vm.message.statusText)+" ")]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"type":"submit","color":"teal white--text","disabled":invalid || !validate || _vm.loading}},[_vm._v("Login")])],1)],1)]}}])}),_c('v-overlay',{attrs:{"absolute":"","opacity":0.58,"color":'white',"value":_vm.loading}},[_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }